<template>
    <div class="upload-images-container">
        <a-upload
                :show-upload-list="false"
                :action="action"
                @change="handleChange"
                :before-upload="beforeUpload"
                v-if="images.length < max "
        >

            <div class="upload-image-item upload-btn"></div>

        </a-upload>

        <template v-for="(image,ik) in images">
            <div class="upload-image-item" :key="ik">
                <img :src="image" alt="">
                <div class="image-opera">
                    <a-button @click="delImage(ik)" type="link">
                        <span class="upload-del"></span>
                    </a-button>
                    <a-button @click="previewImage(ik)" type="link">
                        <span class="upload-fd"></span>
                    </a-button>
                </div>
            </div>
        </template>

        <template v-if="preview">

            <a-modal class="img-modal" v-model="preview" :footer="null">
                <img @click="preview = false" :src="previewImageUrl" alt="avatar"/>
            </a-modal>

        </template>

    </div>
</template>

<script>
import api from "../../../../repo/api";
import auth from "../../../../auth";

export default {
    name: "ImagesUploader",
    model: {
        event: 'change',
        prop: 'images'
    },
    props: {
        max: {
            type: Number,
            default() {
                return 6;
            }
        },
        images: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    watch: {
        images() {
            this.imagesList = this.images
        }
    },
    data() {

        let appId = localStorage['mini_app_id'];

        return {
            imagesList: this.images,
            preview: false,
            previewImageUrl: null,
            action: api.colUrl('/ast-shop/upload-image?token=' + auth.getToken() + '&_mini_app_id=' + appId)
        }

    },
    methods: {

        delImage(key) {
            this.imagesList.splice(key, 1)
            this.$emit('change', this.imagesList)
        },
        previewImage(key) {
            this.previewImageUrl = this.images[key]

            this.preview = true
        },
        handleChange(info) {

            if (info.file.status === 'uploading') {
                this.$loading.show();
                return;
            }

            if (info.file.status === 'done') {

                this.$loading.hide();

                let response = info.file.response;

                if (response.code !== 0) {

                    return this.$message.error(response.msg)
                }

                let url = ''

                if (typeof this.done === 'function') {
                    url = this.done(response.data)
                } else {
                    url = response.data

                    this.imagesList.push(url)

                    this.$emit('change', this.imagesList)
                }
            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

            if (!isJpgOrPng) {
                this.$message.error('你只能上传图片文件!');
            }

            const isLt1M = file.size / 1024 / 1024 < 2;

            if (!isLt1M) {
                this.$message.error('文件必须小于2M!');
            }

            return isJpgOrPng && isLt1M;
        },
    }
}
</script>

<style lang="less" scoped>
.upload-btn {
    cursor: pointer;
    background-size: 24px;
    background: url("/assets/icons/upload-add-gray.png") center no-repeat;
}

.upload-image-item {
    width: 120px;
    height: 120px;
    background-color: #F5F5F5;
    margin-right: 16px;
    margin-bottom: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    position: relative;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    &:hover {
        .image-opera {
            opacity: 1;
        }

    }
}

.image-opera {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    line-height: 0;
    transition: opacity .5s;
}

.upload-del, .upload-fd {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("/assets/icons/upload-del.png");
    background-size: 20px;
}

.upload-fd {
    background-image: url("/assets/icons/upload-fd.png");
}

.upload-images-container {
    display: flex;
    flex-wrap: wrap;


}

.img-modal {
    img {
        max-width: 100%;
    }
}
</style>